import {
  Assessment,
  AssessmentData,
  CareerDriversQuestion,
  GamesQuestion,
  PersonalityQuestion,
  ProfileQuestion,
  SituationalQuestion,
  SurveyQuestion,
  TechnicalQuestion,
  VideoQuestion,
} from 'models/assessment';
import { ACTION_TYPES, AssessmentAction } from './Actions';

interface AssessmentState {
  assessmentForDuplication: Assessment | null;
  assessmentData: AssessmentData | null;
  personalityData: PersonalityQuestion | null;
  gamesData: GamesQuestion | null;
  careerDriversData: CareerDriversQuestion | null;
  profileQueData: ProfileQuestion | null;
  videoData: VideoQuestion | null;
  sjtData: SituationalQuestion | null;
  allAssList: Assessment[];
  filteredAssList: Assessment[];
  ongoingAssList: Assessment[];
  completedAssList: Assessment[];
  archivedAssList: Assessment[];
  surveyQueData: SurveyQuestion | null;
  technicalQueData: TechnicalQuestion | null;
  statusFilter: string;
  aiAssessmentId: number;
  aiJobDesign: [];
}

const initState: AssessmentState = {
  assessmentForDuplication: null,
  assessmentData: null,
  personalityData: null,
  gamesData: null,
  careerDriversData: null,
  profileQueData: null,
  videoData: null,
  sjtData: null,
  allAssList: [],
  filteredAssList: [],
  ongoingAssList: [],
  completedAssList: [],
  archivedAssList: [],
  surveyQueData: null,
  technicalQueData: null,
  statusFilter: '',
  aiAssessmentId: 0,
  aiJobDesign: [],
};

const Reducer = (
  state: AssessmentState = initState,
  action: AssessmentAction,
): AssessmentState => {
  switch (action.type) {
    case ACTION_TYPES.ADD_ASSESSMENT:
      return {
        ...state,
        assessmentData: action.data,
      };
    case ACTION_TYPES.GET_ASSESSMENT:
      return {
        ...state,
      };
    case ACTION_TYPES.PERSONALITY_ASSESSMENT:
      return {
        ...state,
        personalityData: action.data,
      };
    case ACTION_TYPES.GAMES_ASSESSMENT:
      return {
        ...state,
        gamesData: action.data,
      };
    case ACTION_TYPES.CAREER_DRIVERS:
      return {
        ...state,
        careerDriversData: action.data,
      };
    case ACTION_TYPES.PROFILE_QUESTION:
      return {
        ...state,
        profileQueData: action.data,
      };
    case ACTION_TYPES.VIDEO_QUESTION:
      return {
        ...state,
        videoData: action.data,
      };
    case ACTION_TYPES.SJT_QUESTION:
      return {
        ...state,
        sjtData: action.data,
      };

    case ACTION_TYPES.ALL_ASS_LIST:
      return {
        ...state,
        allAssList: action.data,
      };

    case ACTION_TYPES.FILTERED_ASS_LIST:
      return {
        ...state,
        filteredAssList: action.data,
      };
    case ACTION_TYPES.SURVEY_QUESTION:
      return {
        ...state,
        surveyQueData: action.data,
      };
    case ACTION_TYPES.TECHNICAL_QUESTION:
      return {
        ...state,
        technicalQueData: action.data,
      };
    case ACTION_TYPES.ASS_STATUS_FILTER:
      return {
        ...state,
        statusFilter: action.data,
      };
    case ACTION_TYPES.SETUP_DUPLICATION:
      return {
        ...state,
        assessmentForDuplication: action.data,
      };
    case ACTION_TYPES.AI_ASSESSMENT_ID:
      return {
        ...state,
        aiAssessmentId: action.data,
      };
    case ACTION_TYPES.AI_JOB_DESIGN:
      return {
        ...state,
        aiJobDesign: action.data,
      };
    default:
      return state;
  }
};

export default Reducer;
