import { TYPE_IDS } from 'Helpers/Paths';
import {
  Assessment,
  AssessmentData,
  CareerDriversQuestion,
  TypeIds,
  GamesQuestion,
  PersonalityQuestion,
  ProfileQuestion,
  SituationalQuestion,
  SurveyQuestion,
  TechnicalQuestion,
  TestSetup,
  VideoQuestion,
} from 'models/assessment';

export const ACTION_TYPES = {
  ADD_ASSESSMENT: 'ADD_ASSESSMENT',
  GET_ASSESSMENT: 'GET_ASSESSMENT',
  PERSONALITY_ASSESSMENT: 'GET_PERSONALITY_ASSESSMENT',
  GAMES_ASSESSMENT: 'GET_GAMES_ASSESSMENT',
  CAREER_DRIVERS: 'GET_CAREER_DRIVERS_ASSESSMENT',
  PROFILE_QUESTION: 'PROFILE_QUESTION_ASSESSMENT',
  VIDEO_QUESTION: 'VIDEO_ASSESSMENT',
  SJT_QUESTION: 'SJT_ASSESSMENT',
  ALL_ASS_LIST: 'ALL_ASS_LIST',
  FILTERED_ASS_LIST: 'FILTERED_ASS_LIST',
  SURVEY_QUESTION: 'SURVEY_QUESTION_ASSESSMENT',
  TECHNICAL_QUESTION: 'TECHNICAL_QUESTION_ASSESSMENT',
  ASS_STATUS_FILTER: 'ASS_STATUS_FILTER',
  SETUP_DUPLICATION: 'SETUP_DUPLICATION',
  AI_ASSESSMENT_ID: 'AI_ASSESSMENT_ID',
  AI_JOB_DESIGN: 'AI_JOB_DESIGN',
} as const;

export const setAiAssessmentId = (data: number) => {
  return {
    type: ACTION_TYPES.AI_ASSESSMENT_ID,
    data,
  };
};

export type SetAiAssessmentId = typeof setAiAssessmentId;
export type SetAiAssessmentIdAction = ReturnType<SetAiAssessmentId>;

export const setAiJobDesign = (data: []) => {
  return {
    type: ACTION_TYPES.AI_JOB_DESIGN,
    data,
  };
};

export type SetAiJobDesign = typeof setAiJobDesign;
export type SetAiJobDesignAction = ReturnType<SetAiJobDesign>;

export const setDuplicationAssessment = (data: Assessment) => {
  return {
    type: ACTION_TYPES.SETUP_DUPLICATION,
    data,
  };
};

export type SetDuplicationAssessment = typeof setDuplicationAssessment;
export type SetDuplicationAssessmentAction =
  ReturnType<SetDuplicationAssessment>;

export const setAssessmentData = (data: AssessmentData) => {
  // SET YOUR ASSESSMENT INFO HERE
  return {
    type: ACTION_TYPES.ADD_ASSESSMENT,
    data,
  };
};
export type SetAssessmentData = typeof setAssessmentData;
export type SetAssessmentDataAction = ReturnType<SetAssessmentData>;

export const getAssessmentData = () => {
  // SET YOUR ASSESSMENT INFO HERE
  return {
    type: ACTION_TYPES.GET_ASSESSMENT,
  };
};
export type GetAssessmentData = typeof getAssessmentData;
export type GetAssessmentDataAction = ReturnType<GetAssessmentData>;

export const setPersonalityData = (
  data: TestSetup['items'],
  score_knockout: number = 0,
  id: TypeIds['PERSONALITY'],
) => {
  // SET YOUR ASSESSMENT INFO HERE
  const mainData: PersonalityQuestion | null = id
    ? {
        [TYPE_IDS.PERSONALITY]: {
          items: data,
          score_knockout,
        },
      }
    : null;
  return {
    type: ACTION_TYPES.PERSONALITY_ASSESSMENT,
    data: mainData,
  };
};
export type SetPersonalityData = typeof setPersonalityData;
export type SetPersonalityDataAction = ReturnType<SetPersonalityData>;

export const setGamesData = (
  data: TestSetup['items'],
  score_knockout: number,
  id: TypeIds['GAMES'],
) => {
  // SET YOUR ASSESSMENT INFO HERE
  const mainData: GamesQuestion | null = id
    ? {
        [TYPE_IDS.GAMES]: {
          items: data,
          score_knockout,
        },
      }
    : null;
  return {
    type: ACTION_TYPES.GAMES_ASSESSMENT,
    data: mainData,
  };
};
export type SetGamesData = typeof setGamesData;
export type SetGamesDataAction = ReturnType<SetGamesData>;

export const setVideoData = (
  data: TestSetup['items'],
  score_knockout: number = 0,
  id: TypeIds['VIDEO'] | null,
) => {
  // SET YOUR ASSESSMENT INFO HERE
  const mainData: VideoQuestion | null = id
    ? {
        [id]: {
          items: data,
          score_knockout,
        },
      }
    : null;
  return {
    type: ACTION_TYPES.VIDEO_QUESTION,
    data: mainData,
  };
};
export type SetVideoData = typeof setVideoData;
export type SetVideoDataAction = ReturnType<SetVideoData>;

export const setSituationalData = (
  data: TestSetup['items'],
  score_knockout: number = 0,
  id: TypeIds['SITUATIONAL'] | null,
) => {
  // SET YOUR ASSESSMENT INFO HERE
  const mainData: SituationalQuestion | null = id
    ? {
        [id]: {
          items: data,
          score_knockout,
        },
      }
    : null;
  return {
    type: ACTION_TYPES.SJT_QUESTION,
    data: mainData,
  };
};
export type SetSjtData = typeof setSituationalData;
export type SetSjtDataAction = ReturnType<SetSjtData>;

export const setCareerDriversData = (
  data: TestSetup['items'],
  id: TypeIds['CAREER_DRIVERS'] | null,
  score_knockout: number = 0,
) => {
  // SET YOUR ASSESSMENT INFO HERE
  const mainData: CareerDriversQuestion | null = id
    ? {
        [id]: {
          items: data,
          score_knockout,
        },
      }
    : null;
  return {
    type: ACTION_TYPES.CAREER_DRIVERS,
    data: mainData,
  };
};
export type SetCareerDriversData = typeof setCareerDriversData;
export type SetCareerDriversDataAction = ReturnType<SetCareerDriversData>;

export const setProfileQuestionData = (
  data: TestSetup['items'],
  score_knockout: number,
  id: TypeIds['PROFILE_QUESTION'] | null,
) => {
  // SET YOUR ASSESSMENT INFO HERE
  const mainData: ProfileQuestion | null = id
    ? {
        [id]: {
          items: data,
          score_knockout,
        },
      }
    : null;
  return {
    type: ACTION_TYPES.PROFILE_QUESTION,
    data: mainData,
  };
};
export type SetProfileQueData = typeof setProfileQuestionData;
export type SetProfileQueDataAction = ReturnType<SetProfileQueData>;

export const setAllAssList = (data: Assessment[]) => {
  // SET YOUR ASSESSMENT INFO HERE
  return {
    type: ACTION_TYPES.ALL_ASS_LIST,
    data: data,
  };
};
export type SetAllAssList = typeof setAllAssList;
export type SetAllAssListAction = ReturnType<SetAllAssList>;

export const setFilteredAssList = (data: Assessment[]) => {
  // SET YOUR ASSESSMENT INFO HERE
  return {
    type: ACTION_TYPES.FILTERED_ASS_LIST,
    data: data,
  };
};
export type SetFilteredAssList = typeof setFilteredAssList;
export type SetFilteredAssListAction = ReturnType<SetFilteredAssList>;

export const setSurveyQueData = (
  data: TestSetup['items'],
  score_knockout: number = 0,
  id: TypeIds['SURVEY'] | null,
) => {
  // SET YOUR ASSESSMENT INFO HERE
  const mainData: SurveyQuestion | null = id
    ? {
        [id]: {
          items: data,
          score_knockout,
        },
      }
    : null;
  return {
    type: ACTION_TYPES.SURVEY_QUESTION,
    data: mainData,
  };
};
export type SetSurveyQueData = typeof setSurveyQueData;
export type SetSurveyQueDataAction = ReturnType<SetSurveyQueData>;

export const setTechnicalQueData = (
  data: TestSetup['items'],
  score_knockout: number = 0,
  id: TypeIds['TECHNICAL'] | null,
) => {
  // SET YOUR ASSESSMENT INFO HERE
  const mainData: TechnicalQuestion | null = id
    ? {
        [id]: {
          items: data,
          score_knockout,
        },
      }
    : null;
  return {
    type: ACTION_TYPES.TECHNICAL_QUESTION,
    data: mainData,
  };
};
export type SetTechnicalQueData = typeof setTechnicalQueData;
export type SetTechnicalQueDataAction = ReturnType<SetTechnicalQueData>;

export const setAssessmentStatusFilter = (data: string) => {
  // SET YOUR ASSESSMENT INFO HERE
  return {
    type: ACTION_TYPES.ASS_STATUS_FILTER,
    data: data,
  };
};
export type SetAssessmentStatusFilter = typeof setAssessmentStatusFilter;
export type SetAssessmentStatusFilterAction =
  ReturnType<SetAssessmentStatusFilter>;

export type AssessmentAction =
  | SetAssessmentDataAction
  | GetAssessmentDataAction
  | SetPersonalityDataAction
  | SetGamesDataAction
  | SetVideoDataAction
  | SetSjtDataAction
  | SetCareerDriversDataAction
  | SetProfileQueDataAction
  | SetAllAssListAction
  | SetFilteredAssListAction
  | SetSurveyQueDataAction
  | SetTechnicalQueDataAction
  | SetAssessmentStatusFilterAction
  | SetDuplicationAssessmentAction
  | SetAiAssessmentIdAction
  | SetAiJobDesignAction;
